<template>
  <div class="content">
    <div class="member-search">
      <div class="search-form" @keyup.enter="handleSearch">
        <Form :label-width="90" :model="searchData" ref="formValidate">
          <Row :gutter="16">
            <template v-if="!branchShow">
              <Col span="6">
                <FormItem label="省份">
                  <Select
                    clearable
                    v-model="searchData.pid"
                    placeholder="请选择"
                    @on-change="changePro"
                  >
                    <Option :value="v.id" v-for="v in proList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span="6">
                <FormItem label="城市">
                  <Select
                    clearable
                    v-model="searchData.cid"
                    placeholder="请选择"
                    @on-change="changeCity"
                    @click.native="changeBossCity"
                  >
                    <Option :value="v.id" v-for="v in cityList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span="6">
                <FormItem label="门店">
                  <Select
                    clearable
                    v-model="searchData.sid"
                    placeholder="请选择"
                    @on-change="changeStore"
                    @click.native="changeBossBranch"
                  >
                    <Option :value="v.id" v-for="v in storeList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span="6">
                <FormItem label="会员">
                  <Select
                    clearable
                    filterable
                    placeholder="请选择"
                    v-model="searchData.member_id"
                  >
                    <Option
                      v-for="item in userList"
                      :value="item.id"
                      :key="item.id"
                    >{{ item.name }}</Option>
                  </Select>
                </FormItem>
              </Col>
            </template>
            <template v-else>
              <Col span="6">
                <FormItem label="省份">
                  <Input disabled v-model="proName"></Input>
                </FormItem>
              </Col>
              <Col span="5">
                <FormItem label="城市">
                  <Input disabled v-model="cityName"></Input>
                </FormItem>
              </Col>
              <Col span="6">
                <FormItem label="门店">
                  <Input disabled v-model="sName"></Input>
                </FormItem>
              </Col>
              <Col span="6">
                <FormItem label="会员">
                  <Select
                    clearable
                    placeholder="请选择"
                    v-model="searchData.member_id"
                  >
                    <Option
                      v-for="item in userList"
                      :value="item.id"
                      :key="item.id"
                    >{{ item.true_name }}</Option>
                  </Select>
                </FormItem>
              </Col>
            </template>
          </Row>
          <Row :gutter="16">
            <Col span="6">
              <FormItem label="卡系">
                <Select clearable v-model="searchData.kxlx_id" placeholder="请选择">
                  <Option
                    v-for="item in cardTypes"
                    :value="item.id"
                    :key="item.id"
                  >{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="类型">
                <Select clearable v-model="searchData.cjlx" placeholder="请选择">
                  <Option value="问题促进">问题促进</Option>
                  <Option value="预警促进">预警促进</Option>
                  <Option value="预约促进">预约促进</Option>
                  <Option value="数据促进">数据促进</Option>
                  <Option value="生日促进">生日促进</Option>
                  <Option value="其他促进">其他促进</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="会员状态">
                <Select clearable v-model="searchData.hyzt" placeholder="请选择">
                  <Option value="2">1会员</Option>
                  <Option value="4">2未续</Option>
                  <Option value="5">3封存</Option>
                  <Option value="6">4新顾客</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="日期范围">
                <DatePicker
                  @on-change="handleChangeDate"
                  type="daterange"
                  placeholder="选择日期"
                  style="width:100%;"
                ></DatePicker>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col :lg="{ span: 12, offset: 12 }" :md="{ span: 12, offset: 12 }">
              <Button type="warning" @click="handleSearch">&nbsp;&nbsp;搜索&nbsp;&nbsp;</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
    <div class="content-panel">
      <div class="left-panel">
        <div class="left-panel--top">
          <div class="panel-categories">
            <div class="panel-categories-item">
              <span class="category-name">预警总数</span>
              <span>{{ alarmData.yjzs }}</span>
            </div>
            <div class="panel-categories-item">
              <span class="category-name">新增预警数量</span>
              <span>{{ alarmData.xzyjsl }}</span>
            </div>
            <div class="panel-categories-item">
              <span class="category-name">已处理预警数</span>
              <span>{{ alarmData.yclyjs }}</span>
            </div>
            <div class="panel-categories-item">
              <span class="category-name">待处理预警数</span>
              <span>{{ alarmData.dcl }}</span>
            </div>
            <div class="panel-categories-item">
              <span class="category-name">紧急预警数量</span>
              <span>{{ alarmData.jjyjsl }}</span>
            </div>
            <div class="panel-categories-item">
              <span class="category-name">一日未处理预警数</span>
              <span>{{ alarmData.yrwclyjsl }}</span>
            </div>
            <div class="panel-categories-item">
              <span class="category-name">两日未处理预警数</span>
              <span>{{ alarmData.lrwclyjsl }}</span>
            </div>
          </div>
          <div class="left-panel-chart" ref="alarmChart"></div>
        </div>
        <div class="left-panel--bottom">
          <div class="left-panel-sub">
            <div class="panel-categories">
              <div class="panel-categories-item">
                <span class="category-name">问题总数</span>
                <span>{{ problemData.wtzs }}</span>
              </div>
              <div class="panel-categories-item">
                <span class="category-name">未处理问题数</span>
                <span>{{ problemData.wtwclsl }}</span>
              </div>
              <div class="panel-categories-item">
                <span class="category-name">已处理问题数</span>
                <span>{{ problemData.wtyclsl }}</span>
              </div>
            </div>
            <div class="left-panel-chart" ref="problemChart"></div>
          </div>
          <div class="right-panel-sub">
            <div class="panel-categories panel-categories--small">
              <div class="panel-categories-item">
                <span class="category-name">手表7日未上传人数</span>
                <span>{{ valueData.value1 }}</span>
              </div>
              <div class="panel-categories-item">
                <span class="category-name">服用情况30天未上传人数</span>
                <span>{{ valueData.value2 }}</span>
              </div>
              <div class="panel-categories-item">
                <span class="category-name">7日无运动数据人数</span>
                <span>{{ valueData.value3 }}</span>
              </div>
              <div class="panel-categories-item">
                <span class="category-name">30日无饮食数据人数</span>
                <span>{{ valueData.value4 }}</span>
              </div>
            </div>
            <div class="left-panel-data-chart" ref="dataChart"></div>
          </div>
        </div>
      </div>
      <div class="right-panel">
        <div class="panel-categories">
          <div class="panel-categories-item">
            <span class="category-name">待分配促进人数</span>
            <span class="blue">{{ undoData.dfpcjrs }}</span>
          </div>
        </div>
        <div class="panel-categories panel-categories--small">
          <div class="panel-categories-item">
            <span class="category-name">已分配任务数量</span>
            <span class="blue">{{ undoData.yfprwsl }}</span>
          </div>
          <div class="panel-categories-item">
            <span class="category-name">促进完成数量</span>
            <span class="blue">{{ undoData.cjwcsl }}</span>
          </div>
          <div class="panel-categories-item">
            <span class="category-name">处理中数量</span>
            <span class="blue">{{ undoData.clzsl }}</span>
          </div>
        </div>
        <div class="panel-action">
          <Button type="primary" @click="confirmTask">确定任务</Button>
          <Button type="primary" @click="openModal">今日任务</Button>
        </div>
        <div class="content-body">
          <Table :columns="columns" :data="resultData" @on-selection-change="handleTableSelection"></Table>
          <Row>
            <Col span="12">
              <div class="table-oprate"></div>
            </Col>
            <Col span="12">
              <span class="records">共 {{ pageTotal }} 条记录</span>
            </Col>
          </Row>
          <div class="page-nations-wrapper">
            <div class="page-nations">
              <Page
                :total="pageTotal"
                :current="pageData.page"
                :page-size="pageData.size"
                @on-change="handleChangePage"
              ></Page>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="showModal"
      title="今日任务"
      class-name="vertical-center-modal"
      width="90%"
    >
      <div class="modal-tabs">
        <div :class="['modal-tabs-item', { active: tabIndex === 'all'}]" @click="changeModalTab('all')">全部任务</div>
        <div :class="['modal-tabs-item', { active: tabIndex === 'mine'}]" @click="changeModalTab('mine')">我的任务</div>
      </div>
      <div class="modal-row"><span>{{ time }}</span></div>
      <div class="modal-row" style="font-size: 24px;">
        <span>今日促进任务 {{ totalTask }} 人，目前已促进 {{ doneTask }} 人。</span>
      </div>
      <Form :label-width="90">
        <FormItem label="任务进度">
          <Progress
            :percent="totalTask ? Math.round(doneTask / totalTask * 100) : 100"
            :stroke-width="20"
            status="active"
          />
        </FormItem>
        <Row>
          <Col :span="6">
            <FormItem label="处理状态">
              <Select clearable v-model="modalParams.status" placeholder="请选择">
              </Select>
            </FormItem>
          </Col>
          <Col :span="6">
            <FormItem label="促进师">
              <Select clearable v-model="modalParams.staff_id" placeholder="请选择">
              </Select>
            </FormItem>
          </Col>
          <Col :span="6">
            <FormItem label="会员姓名">
              <Input v-model="modalParams.member" />
            </FormItem>
          </Col>
          <Col :span="6">
            <FormItem :label-width="20">
              <Button type="primary">搜索</Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <Table :columns="tabIndex === 'all' ? modalColumns1 : modalColumns" :data="modalData"></Table>
      <Row>
        <Col span="12">
          <div class="table-oprate"></div>
        </Col>
        <Col span="12">
          <span class="records">共 {{ totalTask }} 条记录</span>
        </Col>
      </Row>
      <div class="page-nations-wrapper">
        <div class="page-nations">
          <Page
            :total="totalTask"
            :current="modalParams.page"
            :page-size="modalParams.size"
            @on-change="handleModalPage"
          ></Page>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import echarts from 'echarts';
import memberService from '@/services/memberService';
import comparisonService from '@/services/comparisonService';
import familyService from '@/services/familyService';
import boostService from '@/services/boostService';

export default {
	data() {
		return {
			pageData: {
				page: 1,
				size: 12,
			},
			searchData: {
				pid: '',
				cid: '',
				sid: '',
				member_id: '',
				start: '',
				end: '',
				kxlx_id: '',
				cjlx: '',
				hyzt: '',
			},
			isAdmin: '',
			staffList: [],
			proList: [],
			cityList: [],
			storeList: [],
			userList: [],
			columns: [
				{ type: 'selection', align: 'center', width: 80 },
				{ title: '促进类型', key: 'cjlx', align: 'center' },
				{ title: '门店', key: 'md', align: 'center', width: 100 },
				{ title: '顾客名称', key: 'name', align: 'center' },
				{ title: '年龄', key: 'age', align: 'center', width: 80 },
				{ title: '性别', key: 'sex_show', align: 'center', width: 80 },
			],
			resultData: [],
			pageTotal: 0,
			branchShow: false,
			branch_id: [],
			power: [],
			proName: '',
			cityName: '',
			sName: '',
			alarmData: {
				yjzs: 0,
				xzyjsl: 0,
				yclyjs: 0,
				dcl: 0,
				jjyjsl: 0,
				yrwclyjsl: 0,
				lrwclyjsl: 0,
			},
			problemData: {
				wtzs: 0,
				wtwclsl: 0,
				wtyclsl: 0,
			},
			valueData: {
				value1: 0,
				value2: 0,
				value3: 0,
				value4: 0,
			},
			undoData: {
				cjwcsl: 0,
				yfprwsl: 0,
				clzsl: 0,
				dfpcjrs: 0,
			},
			checkedTask: [],
			showModal: false,
			modalParams: {
				size: 10,
				page: 1,
				status: '',
				staff_id: '',
				member: '',
				show_all: true,
			},
			modalColumns: [
				{ title: '时间', key: 'rwrq', align: 'center', width: 120 },
				{ title: '所属门店', key: 'md', align: 'center' },
				{ title: '会员姓名', key: 'name', align: 'center' },
				{ title: '促进类型', key: 'cjlx', align: 'center', width: 120 },
				{ title: '促进内容', key: 'cjnr', align: 'center' },
				{ title: '促进问题', key: 'cjwt', align: 'center', width: 300, tooltip: true },
				{ title: '会员手机号', key: 'mobile', align: 'center', width: 130 },
				{ title: '状态', key: 'status', align: 'center', width: 100 },
				{ title: '处理记录', key: 'cljl', align: 'center', width: 300, tooltip: true },
				{
					title: '操作',
					align: 'center',
					render: (h, params) => {
						const self = this;
						const { id, member_id, status } = params.row;
						return h(
							'Button',
							{
								attrs: {
									type: 'primary',
									size: 'small',
								},
								on: {
									click() {
										self.$router.push(`/promotion-manage/${member_id}/${id}`);
									},
								},
							},
							status === '已处理' ? '查看促进' : '开始促进',
						);
					},
				},
			],
			modalColumns1: [
				{ title: '时间', key: 'rwrq', align: 'center', width: 120 },
				{ title: '所属门店', key: 'md', align: 'center' },
				{ title: '会员姓名', key: 'name', align: 'center' },
				{ title: '促进类型', key: 'cjlx', align: 'center', width: 120 },
				{ title: '促进内容', key: 'cjnr', align: 'center' },
				{ title: '促进问题', key: 'cjwt', align: 'center', width: 300, tooltip: true },
				{ title: '会员手机号', key: 'mobile', align: 'center', width: 130 },
				{ title: '状态', key: 'status', align: 'center', width: 100 },
				{ title: '处理记录', key: 'cljl', align: 'center', width: 300, tooltip: true },
				{ title: '促进员工', key: 'staff_name', align: 'center' },
			],
			modalData: [],
			totalTask: 0,
			doneTask: 0,
			time: '',
			timer: null,
			tabIndex: 'all',
			cardTypes: [],
		};
	},
	methods: {
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			if (this.searchData.sid) {
				const params = {
					page: 1,
					size: 1000,
					pid: this.searchData.pid,
					cid: this.searchData.cid,
					sid: this.searchData.sid,
				};
				comparisonService.getUserList(params).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		initData() {
			let list = JSON.parse(localStorage.getItem('userInfo'));
			memberService.getBase().then((data) => {
				this.proList = data.branch.pro;
				this.city = data.branch.city;
				this.store = data.branch.store;
				this.power = list.user_info.power;
				this.memberTypeData = data.member_type;
				if (this.power.indexOf('服务管理--查看所属店面会员信息权限') === -1) {
					this.branchShow = false;
				} else {
					if (
						this.power.indexOf('服务管理--查看全国会员信息权限') === -1 &&
						this.power.indexOf('服务管理--查看所属区域会员信息权限') === -1
					) {
						this.branchShow = true;
						this.branch_id = list.user_info.branch;
						this.searchData.sid = this.branch_id[2];
						this.searchData.cid = this.branch_id[1];
						this.searchData.pid = this.branch_id[0];
						this.proName = list.user_info.pro_name;
						this.cityName = list.user_info.city_name;
						this.sName = list.user_info.branch_name;
						this.changeStore();
					}
				}
			});
		},
		getQuery() {
			const params = { ...this.pageData, search_json: JSON.stringify(this.searchData) };
			boostService.undoList(params).then((data) => {
				this.resultData = data.list;
				this.pageTotal = data.row_size;
			});
		},
		handleSearch() {
			this.pageData.page = 1;
			this.getQuery();
			this.getUndo();
			this.getProblems();
		},
		// 促进日期
		handleChangeDate(date) {
			this.searchData.start = date[0];
			this.searchData.end = date[1];
		},
		// 页码
		handleChangePage(page) {
			this.pageData.page = page;
			this.getQuery();
		},
		// 点击服务员工先选择门店
		handleChangeStaff() {
			if (!this.searchData.sid) {
				this.$Message.warning('请先选择门店');
			}
		},
		// 点击服务客服先选择服务部门
		handleChangeServiceCustomer() {
			if (!this.searchData.bm_id) {
				this.$Message.warning('请先选择服务部门');
			}
		},
		// 点击门店先选择城市
		changeBossBranch() {
			if (!this.searchData.cid) {
				this.$Message.warning('请先选择城市');
			}
		},
		// 点击城市先选择省份
		changeBossCity() {
			if (!this.searchData.pid) {
				this.$Message.warning('请先选择省份');
			}
		},
		getAllUsers() {
			familyService.getAllUsers().then((data) => {
				this.userList = data.list.map((item) => {
					item.name = `${item.name} - ${item.branch_name}`;
					return item;
				});
			});
		},
		getAlarms() {
			boostService.alarmStatistics().then((data) => {
				this.alarmData = data;
				const total = [];
				const done = [];
				const undo = [];
				data.chart.forEach((d) => {
					total.push(d.data[0]);
					done.push(d.data[1]);
					undo.push(d.data[2]);
				});
				echarts.init(this.$refs.alarmChart).setOption({
					color: ['#5187ec', '#3ad2d3', '#fda338'],
					tooltip: {
						trigger: 'axis',
						axisPointer: { type: 'shadow' },
					},
					grid: {
						top: 20,
						bottom: 30,
						left: 50,
						right: 30,
					},
					xAxis: [
						{
							type: 'category',
							axisTick: { show: false },
							data: ['血压', '睡眠', '心率', '血脂', '血糖', '血氧'],
						},
					],
					yAxis: [
						{
							type: 'value',
							splitLine: { lineStyle: { color: '#efefef' } },
						},
					],
					series: [
						{
							name: '总数',
							type: 'bar',
							barWidth: 40,
							barGap: 0,
							data: total,
						},
						{
							name: '已处理',
							type: 'bar',
							barWidth: 40,
							data: done,
						},
						{
							name: '未处理',
							type: 'bar',
							barWidth: 40,
							data: undo,
						},
					],
				});
			});
		},
		getProblems() {
			boostService.problemStatistics().then((data) => {
				this.problemData = data;
				echarts.init(this.$refs.problemChart).setOption({
					color: ['#5187ec', '#3ad2d3', '#58a55c', '#fda338'],
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b}: {c} ({d}%)',
					},
					legend: { bottom: 10 },
					series: [
						{
							type: 'pie',
							radius: ['40%', '60%'],
							top: -40,
							avoidLabelOverlap: false,
							emphasis: {
								label: {
									show: true,
									fontSize: '30',
									fontWeight: 'bold',
								},
							},
							data: data.chart.map((d) => {
								d.value = d.count;
								return d;
							}),
						},
					],
				});
			});
		},
		getData() {
			boostService.dataStatistics().then((data) => {
				const { part1, chart } = data;
				this.valueData = part1;
				echarts.init(this.$refs.dataChart).setOption({
					color: ['#5187ec', '#3ad2d3', '#fda338'],
					tooltip: {
						trigger: 'axis',
						axisPointer: { type: 'shadow' },
					},
					grid: {
						top: 20,
						bottom: 30,
						left: 50,
						right: 30,
					},
					xAxis: [
						{
							type: 'category',
							axisTick: { show: false },
							data: ['血压', '心率', '睡眠', '血氧', '运动', '饮食'],
						},
					],
					yAxis: [
						{
							type: 'value',
							splitLine: { lineStyle: { color: '#efefef' } },
						},
					],
					series: [
						{
							type: 'bar',
							barWidth: 40,
							data: [chart.xy, chart.xl, chart.sm, chart.spo, chart.yd, chart.ys],
						},
					],
				});
			});
		},
		getUndo() {
			const params = { search_json: JSON.stringify(this.searchData) };
			boostService.undoStatistics(params).then((data) => {
				this.undoData = data;
			});
		},
		confirmTask() {
			if (!this.checkedTask.length) {
				this.$Message.error('请选择任务');
				return;
			}

			boostService.confirmTask({ ids: this.checkedTask.map((d) => d.id).join(',') }).then(() => {
				this.$Message.success('操作成功');
			});
		},
		handleTableSelection(value) {
			this.checkedTask = value;
		},
		openModal() {
			this.showModal = true;
			this.getTask();
		},
		getTask() {
			this.modalParams.show_all = this.tabIndex === 'all';
			boostService.todayTask(this.modalParams).then((data) => {
				const { list, ycj, jrrw } = data;
				this.modalData = list;
				this.doneTask = ycj;
				this.totalTask = jrrw;
			});
		},
		changeModalTab(index) {
			this.tabIndex = index;
			this.modalData = [];
			this.getTask();
		},
		getCardTypes() {
			boostService.cardTypes().then((data) => {
				this.cardTypes = data;
			});
		},
		handleModalPage(page) {
			this.modalParams.page = page;
			this.getTask();
		},
	},
	mounted() {
		this.initData();
		this.getQuery();
		this.getCardTypes();
		this.getAllUsers();
		this.getAlarms();
		this.getProblems();
		this.getData();
		this.getUndo();
		this.isAdmin =
			JSON.parse(localStorage.getItem('loginInfo')).username === 'boss' ||
			JSON.parse(localStorage.getItem('loginInfo')).username === '陶正亮';
	},
	created() {
		this.timer = setInterval(() => {
			this.time = dayjs().format('YYYY-MM-DD HH:mm:ss');
		}, 0);
		memberService.getServiceStaffList().then((data) => {
			this.staffList = data;
		});
	},
	beforeDestroy() {
		clearInterval(this.timer);
	},
};
</script>

<style lang="less" scoped>
.member-search {
  background-color: #fff;
  margin-bottom: 20px;
}
.search-form {
  padding: 20px 15px;
}
.content-panel {
  display: flex;
  gap: 20px;
}
.left-panel {
  width: 65%;
  &--top {
    background-color: #fff;
    margin-bottom: 20px;
  }
  &--bottom {
    display: flex;
    gap: 20px;
  }
}
.right-panel {
  background-color: #fff;
  flex: 1;
}
.left-panel-sub,
.right-panel-sub {
  flex: 1;
  background-color: #fff;
}
.right-panel-sub .panel-categories {
  flex-wrap: wrap;
  .panel-categories-item {
    width: calc(50% - 5px);
  }
}
.panel-categories {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  padding: 10px 0;
  border-bottom: 1px solid #dcdee2;
  margin-bottom: 10px;
  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
  }
  .category-name {
    font-size: 18px;
  }
  &--small {
    .panel-categories-item {
      font-size: 24px;
    }
    .category-name {
      font-size: 16px;
    }
  }
}
.left-panel-chart {
  height: 305px;
}
.left-panel-data-chart {
  height: 240px;
}
.blue {
  color: #5187ec;
}
.panel-action {
  display: flex;
  gap: 10px;
  justify-content: end;
  margin: 0 10px;
}
.modal-row {
  margin: 0 0 10px 20px;
  & +.modal-row {
    margin-bottom: 30px;
  }
}
.modal-tabs {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  .modal-tabs-item {
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
    &.active {
      color: #fdad00;
      border-bottom: 1px solid #fdad00;
    }
  }
}
</style>
